<template>
  <b-container>
    <b-card border-variant="white">
      <b-card-title>PRIVACY POLICY</b-card-title>
    </b-card>

    <b-card align="left" class="mb-2">
      <b-card-text v-html="text"></b-card-text>
    </b-card>
  </b-container>
</template>

<script>
var html = `<h1 style="font-family: Arial, sans-serif;color: #000000;background-color: #ffffff;"></h1>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">PLAIN WEALTH APP/SITE PRIVACY NOTICE</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Protecting your information is important to us. The following guidelines set forth our general privacy practices and principles that apply to information we collect through our Plain Wealth App and any associated website collectively referred to in this document as (the "App") or in aggregate at the Plain Solutions Wealth System, which is owned or operated by Plain Solutions LLC, or companies related by common ownership or control (collectively, "Plain Solutions," "we," "us," or "our") We use this privacy notice to clearly disclose to you our privacy practices in a manner that is easy to understand.  If there is something you do not understand about our privacy practices, please feel free to contact us at support@plainwealth.io.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">This privacy notice does not apply to products or services provided, or information obtained, other than through the App.  We do not intentionally collect information from persons under the age of 13, so please do not use this App if you are under the age of 18. We may change this notice from time to time so please check back often.  If we significantly change our collection, use, or disclosure practices, we will try to send you a notice via email if you have provided us with an email address.  Please keep in mind that some of the services mentioned specifically in this notice may not be available on the app at this time.  This Privacy Notice is effective as of January 15, 2021. </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;"></p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Defined terms:</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Advisor:  A business customer using the App or the Plain Wealth Management System (whether a financial advisor, financial institution, broker-dealer, registered investment advisor, third party administrator, trust company or family office customer) for the purpose of providing personal, family or household financial planning services to Consumers.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Consumer:  An individual who is a customer of the Advisor and who is given credentialed access to the Services by the Advisor.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Alliance Partners:  Businesses or people that the Consumer authorizes to view or access their personal information in the App or Plain Wealth Management System, such as banker, accountant, or attorney retained by the Consumer.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;"></p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">You: A client of Plain Solutions LLC, their employees or associates, or their customers and includes Advisor(s) Advisor Firms, Consumers and any person that interacts with the Plain Solutions Wealth System or App.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;"></p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">“Aggregate” Synonymous Terms: When we use the term “aggregate,” “aggregated,” or “aggregation,” we mean a process undertaken at the direction of the Consumer to gather information from various sources, such as financial institutions and financial accounts, to be consolidated into the Plain Wealth Management System. “Aggregated information” is the information that is gathered into one location through this aggregation process.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;"></p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">What information do we collect from you?</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">As part of providing services to you, we may collect information, including personal information, about you.  Personal information is data that can be used to identify you.  The information includes but is not limited to:</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Name</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Email address.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Phone numbers.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Transaction information.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Biometric information for biometric authentication.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Event information.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Inquiry information.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Demographic information such as your date of birth.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Account information such the username and password for App or Plain Wealth Management System.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Other information you disclose when you ask us a question about our App or our products and services.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Technical information about your use of our App including IP address, device identifier, and viewing information.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Geo-location information telling us from where you are accessing the App allowing us to open up a map or navigation app to your advisor’s office.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Log file data.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;"></p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">How do we collect information from you?</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Direct Collection</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">We collect much of the information listed above directly from you when you submit it on our App. </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Indirect Collection – Cookies and Other Technology</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">As part of offering and providing customizable and personalized services, we use tracking technologies to store and sometimes track information about you.  We may use these technologies to:</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Provide you with personalized content based on your use of our App</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Enable you to use our App more easily by remembering and using contact information and registration information</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Evaluate, monitor, and analyze the use of our App and its traffic patterns to help improve our App and services</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Assist us with ad reporting functions such as to learn which ads are bringing users to our App</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Provide you with a customized user experience</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Conduct internal operations pertaining to testing, monitoring, risk management and administration.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Enable you to use our services, including to complete the registering and onboarding process and to receive coaching or training from us</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">When you download, access, and use the App, it may use technology to automatically collect:</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;"> </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Usage Details. When you access and use the App, we may automatically collect certain details of your access to and use of the App, including traffic data, location data, logs, and other communication data and the resources that you access and use on or through the App.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Device Information. We may collect information about your mobile device and internet connection, including the device’s unique device identifier, IP address, operating system, browser type, mobile network information, and the device’s telephone number.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Stored Information and Files. The App also may access metadata and other information associated with other files stored on your device. </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;"></p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">If you do not want us to collect this information, do not download the App or delete it from your device.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;"></p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">The types of specific tracking technologies we use are:</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;"></p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Cookies (or mobile cookies). A cookie is a small file placed on your smartphone. It may be possible to refuse to accept mobile cookies by activating the appropriate setting on your smartphone or mobile device. However, if you select this setting, you may be unable to access certain parts of our App.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Web Beacons. Pages of the App and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit us, for example, to count users who have visited those pages or opened an email and for other related app statistics (for example, recording the popularity of certain app content and verifying system and server integrity). </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;"></p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">From Third Parties</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">We sometimes also collect from other sources the categories of personal information described above, which we often combine with what we collect from you. We receive such information from the following sources and third parties: business partners (e.g., your third-party business provider), service providers, financial institutions, publicly available sources, Advisers (who collect information directly from Consumers and such information may be made available to a Consumer in the App). </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;"></p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">What choices do you have about the collection of information about you?</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Direct Collection.  If you do not want to share your information, you can choose not to participate in a particular service or activity.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Mobile Cookies.  You may disable cookies on your mobile device.  You may lose some features or functionality when you disable cookies.  </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Log Files.  It is not possible to disable log files.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Marketing Emails.  To ensure that you will not receive recurring marketing or other information from us unless you choose to, we will provide you with simple instructions on each marketing email from us for you to let us know that you have chosen to stop receiving such emails from us.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;"></p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">How do we use your information?</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">We may use your personal information to:</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Answer questions you may have about our products and services</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Personalize app and online content so that it applies to you and your interests</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Make our products, services, and App better</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Compile, analyze and do research on our user’s demographics and behavior</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Detect, investigate, and prevent activities that may violate our policies or be illegal</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	To comply with laws or to respond to lawful request and legal processes</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Provide you with a customized user experience</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Detect, suppress, or prevent illegal activities, fraud, or misuse of data</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	With your consent any other lawful or legal use  </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	As otherwise required by applicable laws</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">We use and share location data only to provide and improve our service.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;"></p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Who is collecting your information?</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">When you are on our App and are asked for personal information, you are sharing that information with us and our affiliates and service providers. Although we generally operate the App and collect the information, we may engage third party service providers to assist us in that collection.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Other websites to which we link may collect personally identifiable information about you when you visit those sites.  The information practices of other websites linked to our websites are not covered by this privacy notice. </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;"></p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">With whom do we share your information?</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">We will not disclose any of your personally identifiable information except when we have your permission or under special circumstances, such as when we believe in good faith that the law requires it or under the circumstances described below.  These are some of the ways that your information may be disclosed: </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Service Providers</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">We occasionally hire other companies to provide limited services on our behalf, including app development and operation, answering customer questions about products or services, sending postal mail or email, analyzing app use, processing payments, providing investor information and processing data.  We will only provide those companies the information they need to deliver the service, and they are contractually prohibited from using that information for any other reason.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Advisors</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Information you provide in the App may be made available to the Advisor or the company which employs the Advisor’s.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Data in the Aggregate</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">We may disclose “blinded” aggregated data and user statistics to prospective partners and other third parties.  Blinded data is data that does not identify an individual person.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Other</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">We also may disclose your information in special cases.  For example, when we believe that we must disclose information to identify, contact or bring legal action against someone who may be violating our Terms of Use, or may be causing injury to or interference with our rights or property, other App users or customers and their information, or anyone else who may be harmed by such activities.  We may disclose, access, or monitor your personal information when we believe in good faith that the law requires it and for administrative and other purposes that we deem necessary to maintain, service and improve our products and services and our information security. </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">As we continue to develop our business, we may buy or sell businesses or assets.  In such transactions, confidential customer information generally is one of the transferred business assets.  In the event of a transaction involving the sale of some or all of our businesses, customer and App visitor information may be one of the transferred assets and may be disclosed in connection with negotiations relating to a proposed transaction.  In such case, the transferred information may become subject to a different privacy notice.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;"></p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">How can I control my personal information?</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">You may contact us at support@plainwealth.io if you wish to view, edit, or delete your personal information from our database, and we will use commercially reasonable efforts to accommodate your request.  </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">If you believe that any inaccurate or inappropriate information has been obtained or provided to others through your use of this app, you should contact a representative at plainsolutions.co.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;"></p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Your California Privacy Rights.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">California Civil Code Section 1798.83 permits our visitors who are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please contact us at support@plainwealth.io. </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Make sure to state that you are a California Resident.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Scope of Notice</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">This notice applies to personal information we collect about California residents both online and offline. It applies to California residents who are users of our App; our customers; individuals inquiring about our products; and all other California residents from whom we collect personal information.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">As defined in the CCPA, personal information includes any information that identifies, relates to, or could reasonably be linked to a California resident or household.  It includes, but is not limited to: identifiers, characteristics of protected classes, commercial information, biometrics, internet or electronic information, geolocation data, audio/visual or similar information, employment and educational information, and inferences drawn from any identifiable information. Personal information does not include publicly available information from a public government record.  </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">This notice does not apply to information that is exempt from CCPA disclosure requirements. This includes personal information of individuals acting in their capacity as representatives of our clients, prospect clients, vendors, and other businesses that we conduct business with to the extent the information is collective with respect to a business-to-business transaction or business relationship. This disclosure also does not apply to individuals who are not California residents, any personal information exempted under Cal. Civ. Code Section 1798.145, or any other personal information not contemplated in the CCPA.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Rights of California Residents Under the CCPA </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">This Privacy Notice for California Residents supplements the information contained above in our general privacy policy and applies solely to all visitors, users, households, and others who reside in the State of California.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Your Rights</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">The California Consumer Privacy Act ("CCPA") grants State of California residents the following rights, to:</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Know what personal information is being collected about them.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Know whether their personal information is sold or disclosed and to whom.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Say no to the sale of their personal information.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Access their personal information. </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Have their personal information deleted (subject to certain exceptions); and </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Have the right to equal service and price, even if they exercise their privacy rights under this law.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Categories of Information Collected</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">We collect, use, and disclose personal information for various business purposes described below. While collection practices vary depending on the business purpose, we collect and have collected the following categories of information over the past 12 months, as defined in the CCPA: (1) Identifiers, (2) Financial, (3) Characteristics of Protected Classifications under California or Federal Law, (4) Commercial Information, (5) Internet or Other Network Activity Information, (6) Geolocation Information, and (7) Inferences Drawn From The Information Collected Above.  Currently, we do not collect Audio, Electronic, Visual, Thermal, Olfactory, or Similar Information, Professional and Employment Related Information or Educational Information.  </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Exceptions to our Disclosure Limitations </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">We also may disclose your information in special cases related to our legal obligations and to protect from the misuse of our App. We may share your personal information regarding legal proceedings if stemming from a violation of our Terms of Use and Sale or causing injury to us, our property, our App our clients or others. We may disclose visitor information when subpoenaed, if ordered or otherwise required by a court of law, arbitrator, or other similar proceeding, for government investigations, with government agencies if required by law, and when we otherwise believe in good faith that any applicable law requires it.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">How We Retain and Store Your Personal Information</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">We retain your personal information for as long as necessary to fulfill the purpose(s) for which we collected it and to comply with applicable laws.  We use reasonable security precautions to protect your information while in storage. </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Requests to Know</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months. Once we receive and confirm your verifiable consumer request, we will disclose to you:</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	The categories of personal information we collected about you.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	The categories of sources for the personal information we collected about you.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Our business or commercial purpose for collecting or selling that personal information.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	The categories of third parties with whom we share that personal information.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	The specific pieces of personal information we collected about you (also called a data portability request).</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">o	Sales, identifying the personal information categories that each category of recipient purchased; and </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">o	Disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Requests to Delete</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies. </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;"> </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">We may deny your deletion request if retaining the information is necessary for us or our service provider(s) to: </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">1.	Complete the transaction for which we collected the personal information, provide a service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise performing our contract with you.  This includes fulfilling a warranty request.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">2.	Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">3.	Debug online services to identify and repair errors that impair existing intended functionality.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">4.	Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">5.	Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">6.	Comply with a legal obligation.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">7.	Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">How to Submit a Request to Know or a Request to Delete</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">To request this information please submit a verifiable consumer request to us by either: </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Calling us at (316) 393-8324.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Emailing us at support@plainwealth.io and asking for a Consumer Request Form</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Only you, or someone legally authorized to act on your behalf, may make a verifiable consumer request related to your personal information. To designate an authorized agent, email us at support@plainwealth.io and we will provide you with a form to make a designated agent request. Please do not send us any sensitive personal information using email. Email transmissions are not secure.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">You may only make a verifiable consumer request for access or data portability twice within a 12-month period. </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you. We will only use personal information provided in a verifiable consumer request to verify the requestor’s identity or authority to make the request.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">We will only use personal information provided in a verifiable consumer request to verify the requestor's identity or authority to make the request.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">We will try to respond to your request within forty-five (45) days of when we receive it. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing. We will deliver our written response by mail or electronically, at your option. Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request’s receipt. The response we provide will also explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance, specifically by electronic mail communication.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">We do not process privacy requests sent to us using third-party services.  This is because such services often request that we click on hyperlinks which we will not do for data security reasons.  These services are also susceptible to spoofing and other phishing attacks.  One of our highest priorities is to treat your information safely and securely.  If you live in a state that permits you to make a privacy request using a third-party agent, please have them contact us using the information above and we will take steps to identify them and ensure that they are your agent.  </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">An amendment to the CCPA provides an exemption to the Right to Know and Right to Delete for personal information between a business and a person who is acting as an employee, director, officer, or contractor of a company, partnership, sole proprietorship, nonprofit, or government where the information is used in the context of a business transaction.  This exemption is currently set to expire on January 1, 2023.  Until that date, we will not respond to requests to know or delete that meet this exemption.   </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">How to Submit an Opt-Out Request</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">We do not sell your personal information to third parties for money or other consideration.  If we change that practice in the future, we will add a mechanism to enable you to opt-out of such sharing.    </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;"></p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Non-Discrimination</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Deny you goods or services.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Provide you a different level or quality of goods or services.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">•	Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">However, we may offer you certain financial incentives permitted by the CCPA that can result in different prices, rates, or quality levels. Any CCPA-permitted financial incentive we offer will reasonably relate to your personal information's value and contain written terms that describe the program's material aspects. Participation in a financial incentive program requires your prior opt in consent, which you may revoke at any time.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;"></p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Your Nevada Privacy Rights</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Nevada law (SB 220), permits customers in Nevada to opt-out of the sale of certain kinds of personal information. A sale under Nevada law is the transfer of this personal information to third parties for monetary consideration so these third parties can then re-sell or license the sold information.  Plain Solutions does not sell your personal information to third parties as defined in Nevada law. If you are a Nevada resident and wish to opt-out of the sale of your personal information should we change our practices in the future, you must send a request by e-mail at support@plainwealth.io  Make sure to state that you are a Nevada resident.</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;"></p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">Who do you contact with questions or concerns about our Privacy Notice? </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">If you need further assistance, please send an e-mail with your questions or comments to support@plainwealth.io or write us at: </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;"> </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">     Plain Solutions LLC</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">     Attn: Privacy </p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;">     121 N. Mead, Suite 109, Wichita, KS 67202</p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;"></p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;"></p>
<p style="font-family: Georgia, serif;font-size: 14px;font-style: normal;font-weight: normal;color: #000000;background-color: #ffffff;"></p>`;

export default {
  name: "privacy",
  components: {},
  data: function() {
    return {
      text: html
    };
  }
};
</script>
<style lang="scss">
@import "../../scss/theme.scss"; 
</style>
